@import '../../styles/_colors.scss';
@import '../../styles/_fonts.scss';

.active-job-container {
  background-color: #f5f7fa;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;


  .content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 100px;

    /* ------------------------------------------------ Seccion de titulo ------------------------------------------------ */
    .job-header {
      flex-shrink: 0;
      background-color: white;
      border-radius: 10px;
      padding: 24px 32px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.05);

      .title-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h1 {
          font-size: 28px;
          color: #2c3e50;
          margin: 0;
        }

        .timer-badge {
          background-color: #e3f2fd;
          color: #1976d2;
          padding: 10px 20px;
          border-radius: 20px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    /* ------------------------------------------------ Seccion de Informacion ------------------------------------------------ */
    .info-card {
      display: grid;
      flex-shrink: 0;
      background-color: #e3f2fd;
      border-radius: 10px;
      padding: 24px 32px;
      margin-bottom: 20px;
      min-height: 150px;
      max-height: 300px;
      border: 2px solid #1976d2; 
      overflow: auto;
      grid-template-columns: 2fr 2fr; // Dos columnas
      grid-template-rows: 0.5fr 0.5fr 3fr; // Distribución 1/5, 1/5 y 3/5 en la columna izquierda

      .info-item {
        text-align: left; 
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e2e8f0;
      }

      .info-item:nth-child(1) { grid-column: 1 / span 1; grid-row: 1 / span 1; } // Machinery (arriba izquierda)
      .info-item:nth-child(2) { grid-column: 1 / span 1; grid-row: 2 / span 1; } // Location (medio izquierda)
      .info-item:nth-child(3) { grid-column: 1 / span 1; grid-row: 3 / span 1; } // Description (abajo izquierda)
      .info-item:nth-child(4) { grid-column: 2 / span 1; grid-row: 1 / span 3; } // Inventory (columna derecha grande)

      .info-item:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      .info-label {
        font-weight: bold;
        display: block;
        color: #000000;
        font-size: 18px;
        margin-bottom: 8px;
        font-weight: 500;
      }

      .info-value {
        color: #2c3e50;
        font-size: 16px;
        margin: 0;
        line-height: 1.5;
      }
    }

    /* ------------------------------------------------ Seccion de Fotos ------------------------------------------------ */
    .media-section {
      flex: 1; 
      overflow: hidden;  // Evita que el contenido desborde
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      margin-bottom: 20px;
      border: 2px solid #1976d2; 

      .upload-card {
        flex: 1;
        background-color: #e3f2fd;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 24px;
        min-height: 50px;
        display: flex;
        flex-direction: column;
        // overflow: hidden;
        overflow-y: auto; 

        .upload-area {
          border: 2px dashed #e2e8f0;
          border-radius: 8px;
          padding: 20px;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          gap: 20px;

          .upload-label {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 16px;
            text-align: left;

            .upload-icon {
              font-size: 32px;
              color: #1976d2;
            }

            .upload-text-container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .upload-text {
                font-size: 16px;
                font-weight: 500;
                color: #2c3e50;
              }
              
              .upload-hint {
                color: #6b7280;
                font-size: 14px;
                margin-top: 4px;
              }
            }
          }
        }

        .image-preview-grid {
          flex: 1;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
          gap: 12px;
          margin-top: 16px;
          padding-top: 16px;
          border-top: 1px solid #e2e8f0;
          overflow-y: auto;
          max-height: 60vh; // La galería no ocupará más del 60% de la pantalla

          .preview-item {
            position: relative;
            aspect-ratio: 1;
            border-radius: 8px;
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);

            .preview-image {
              width: 100%;
              object-fit: cover;
              transition: transform 0.2s;
            }

            .remove-image-btn {
              position: absolute;
              top: 4px;
              right: 4px;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: rgba(255, 255, 255, 0.9);
              border: none;
              color: #ef5350;
              font-size: 16px;
              line-height: 1;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0;
              transition: all 0.2s;
            }

            .remove-image-btn:hover {
              background-color: #ef5350;
              color: white;
            }

          }

          .preview-item:hover .preview-image {
            transform: scale(1.05);
          }
        }

        .upload-area:hover {
          border-color: #90caf9;
          background-color: #f8fafc;
        }
      }

    }

    /* ------------------------------------------------ Seccion de Botones ------------------------------------------------ */
    .action-buttons {
      margin-top: auto;
      margin-bottom: 0px;
      background-color: white;
      border-radius: 10px;
      padding: 5px 32px;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 16px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      

      .action-btn {
        padding: 16px 24px;
        border: none;
        border-radius: 8px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.3s ease;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.5px;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          opacity: 0.9;
        }

        &.cancel {
            background-color: #ef5350;
            color: white;
        }

        &.close {
            background-color: #e53935;
            color: white;
        }

        &.note {
            background-color: #42a5f5;
            color: white;
        }

        &.parts {
            background-color: #7e57c2;
            color: white;
        }

        &.pause {
            background-color: #ffb74d;
            color: white;
        }

        &.finish {
            background-color: #66bb6a;
            color: white;
        }
      }


    }
  }
}

@media (max-width: 1200px) {
  .action-buttons {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .job-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .active-job-container {
    padding: 16px;
    height: calc(100vh - 32px);
  }

  .job-header {
    padding: 20px;
  }

  .title-section {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .action-buttons {
    left: 16px;
    right: 16px;
    bottom: 16px;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
  }

  .title-section h1 {
    font-size: 24px;
  }

  .timer-badge {
    font-size: 14px;
  }

  .info-card, .upload-card {
    padding: 20px;
  }

  .upload-area {
    padding: 30px;
  }

  .upload-icon {
    font-size: 36px;
  }

  .image-preview-grid {
    max-height: 150px;
  }

  .remove-image-btn {
    width: 18px;
    height: 18px;
    font-size: 14px;
  }

  .media-section {
    max-height: 250px;
  }

  .content-wrapper {
    padding-bottom: 180px; /* More space for 3-row buttons on mobile */
  }
}

.warning-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.warning-content {
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.warning-content h2 {
  color: #2c3e50;
  margin: 0 0 16px 0;
  font-size: 20px;
}

.warning-content p {
  color: #4a5568;
  margin: 0 0 24px 0;
  line-height: 1.5;
}

.warning-buttons {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.warning-btn {
  padding: 10px 24px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 120px;
}

.warning-btn.confirm {
  background-color: #ef5350;
  color: white;
}

.warning-btn.cancel {
  background-color: #f1f4f8;
  color: #4a5568;
  border: none;
}

.warning-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.inventory-list-main {
  margin-top: 8px;
  .inventory-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    margin-bottom: 4px;

    .item-name {
      color: #2d3748;
      font-size: 14px;
    }

    .item-quantity {
      color: #4a5568;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .no-items {
    color: #718096;
    font-style: italic;
    font-size: 14px;
    text-align: center;
    padding: 8px;
  }
} 