@import '../_colors.scss';
@import '../_fonts.scss';

.invoice-generation-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .invoice-content {
    background-color: #f0f4ff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;

    .invoice-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      padding-bottom: 10px;
      border-bottom: 2px solid #1a237e;

      h2 {
        color: #1a237e;
        margin: 0;
        padding: 0;
        border: none;
      }

      .header-info {
        display: flex;
        gap: 20px;
        background-color: #5c6bc0;
        color: white;
        border-radius: 10px;
        padding: 10px 15px;
        font-size: 0.9em;
        text-align: left;

        .client-info, .machinery-info {
          .info-item {
            display: flex;
            align-items: center;
            gap: 8px;
            margin: 2px 0;

            .header-icon {
              width: 20px;
              height: 20px;
              filter: invert(100%) brightness(100%);
            }
          }
        }
      }
    }

    .invoice-body {
      display: grid;
      grid-template-columns: 3fr 2fr;
      gap: 20px;
      margin-bottom: 20px;

      .left-column {
        .search-and-actions {
          display: flex;
          gap: 10px;
          margin-bottom: 20px;

          .MuiTextField-root {
            width: 100%;
      
            .MuiOutlinedInput-root {
              background-color: $table_row_color;
              border-radius: 30px;
              height: 40px;
              padding-right: 0; 
              border-color: darken($table_row_color, 25%);
              
              &:hover {
                border-color: #ffffff;
              }
      
              .MuiInputBase-input {
                color: black;
              }
            }
          }

          .action-buttons {
            display: flex;
            gap: 4px;

            .edit-button {
              background-color: $button_edit_pri_color;
              border-radius: 5px;
              margin-left: 1rem;
              &:hover {
                background-color: darken($button_edit_pri_color, 25%);
              }
            }
          
            .delete-button {
              background-color: $button_delete_pri_color;
              border-radius: 5px;
              margin-left: 1rem;
              &:hover {
                background-color: darken($button_delete_pri_color, 10%);
              }
            }
          
            .add-button {
              background-color: $button_create_pri_color;
              border-radius: 5px;
              margin-left: 1rem;
              &:hover {
                background-color: darken($button_create_pri_color, 20%);
              }
            }

            .MuiIconButton-root img {
              width: 30px;
              height: 30px;
            }
          }
        }

        .invoice-table {
          background-color: white;
          border-radius: 15px;
          overflow: hidden;
          margin-bottom: 20px;

          .table-header {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            background-color: #3f51b5;
            color: white;
            padding: 10px;
            font-weight: bold;

            .col {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
            }
          }
        }

        .comments-section {
          textarea {
            width: 100%;
            padding: 8px;
            border: 1px solid #c5cae9;
            border-radius: 4px;
            resize: vertical;
          }
        }
      }

      .right-column {
        .invoice-details {
          background-color: white;
          padding: 0px 20px 20px 20px; 
          border-radius: 4px;
          .form-group {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            width: 100%;

            label {
              display: block;
              margin-bottom: 2px;
              color: #1a237e;
              font-weight: bold;
              text-align: left; 
            }

            input, select {
              width: 100%;
              padding: 8px;
              border: 1px solid #c5cae9;
              border-radius: 4px;
            }

            input[readonly] {
              background-color: #d4d4d4;
              color: #666;
              border: 1px solid #d0d0d0;
              cursor: not-allowed;
            }

            

          }

          .form-row {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            width: 100%;
            align-items: center;
        
            .form-group {
              display: flex;
              flex-direction: column;
              flex: 2;
            }
            .form-group.right {
              flex: 1; 
            }
          }

          .totals-section {
            margin-top: 20px;
            border-top: 1px solid #ddd;
            padding-top: 15px;

            .tax-selection {
              margin-bottom: 15px;
              
              label {
                display: block;
                margin-bottom: 5px;
                font-weight: 500;
              }

              .tax-select {
                width: 100%;
                padding: 8px;
                border: 1px solid #ddd;
                border-radius: 4px;
                font-size: 14px;
                background-color: white;
              }
            }

            .total-row {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 8px 0;
              
              label {
                font-weight: 500;
              }

              span {
                font-family: monospace;
                font-size: 14px;
              }

              &:last-child {
                border-top: 2px solid #ddd;
                margin-top: 8px;
                padding-top: 12px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: bold;

        &.back-btn {
          background-color: #e8eaf6;
          color: #1a237e;
        }

        &.create-invoice-btn {
          background-color: #1a237e;
          color: white;
        }
      }
    }
  }
}

.table-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  
  &.new-row {
    background-color: #f8f9fa;
    padding: 8px 0;
    
    .col {
      padding: 0 8px;
      flex: 1;
      
      input {
        width: 100%;
        padding: 4px 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
      }
    }

    .row-actions {
      display: flex;
      gap: 8px;
      padding: 0 8px;

      button {
        padding: 4px 12px;
        border-radius: 4px;
        border: none;
        cursor: pointer;
        font-size: 12px;

        &:first-child {
          background-color: #4caf50;
          color: white;
        }

        &:last-child {
          background-color: #f44336;
          color: white;
        }
      }
    }
  }
}

.col {
  flex: 1;
  padding: 12px 8px;
  text-align: left;
}

.action-buttons {
  .MuiIconButton-root {
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.new-row {
  background-color: #f8f9fa;
  
  input {
    width: 90%;
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }

  .row-actions {
    display: flex;
    gap: 8px;
    padding: 0 8px;

    button {
      padding: 4px 8px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-size: 12px;

      &:first-child {
        background-color: #4caf50;
        color: white;
      }

      &:last-child {
        background-color: #f44336;
        color: white;
      }
    }
  }
} 