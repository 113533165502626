.request-parts-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.request-parts-modal {
  background-color: #0a2647;
  border-radius: 10px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
  color: white;
}

.request-parts-modal h2 {
  margin: 0 0 16px 0;
  text-align: center;
  font-size: 24px;
}

.request-parts-modal p {
  margin-bottom: 24px;
  text-align: center;
}

.parts-list {
  background-color: #e8f1ff;
  border-radius: 8px;
  margin-bottom: 16px;
  max-height: 200px;
  overflow-y: auto;
  color: #333;
}

.parts-header {
  display: grid;
  grid-template-columns: 2fr 1fr 40px;
  padding: 12px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.part-item {
  display: grid;
  grid-template-columns: 2fr 1fr 40px;
  padding: 12px;
  align-items: center;
  border-bottom: 1px solid #eee;
}

.remove-part-btn {
  background: none;
  border: none;
  color: #ff4444;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.remove-part-btn:hover {
  background-color: #ffeeee;
}

.search-bar {
  margin-bottom: 24px;
}

.search-bar input {
  width: 90%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.cancel-btn, .confirm-btn {
  padding: 10px 24px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  min-width: 100px;
}

.cancel-btn {
  background-color: #f1f4f8;
  color: #4a5568;
}

.confirm-btn {
  background-color: #ef5350;
  color: white;
}

.add-part-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  z-index: 1100;
}

.add-part-content h3 {
  margin: 0 0 16px 0;
  color: #333;
}

.add-part-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.add-part-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-container {
  position: relative;
  width: 100%;
}

.inventory-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;
}

.inventory-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  gap: 12px;
  border-bottom: 1px solid #eee;
}

.inventory-item:hover {
  background-color: #f5f5f5;
}

.item-code {
  color: #666;
  font-size: 0.9em;
  min-width: 80px;
}

.item-name {
  flex: 1;
}

.selected-part {
  background-color: #f8f9fa;
  padding: 12px;
  border-radius: 4px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selected-part input {
  width: 100px;
}

.add-part-form input[type="number"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-part-form input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0a2647;
  padding: 24px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  z-index: 1200;
  color: white;
  text-align: center;
}

.confirmation-content h2 {
  margin: 0 0 16px 0;
  font-size: 20px;
}

.confirmation-content p {
  margin-bottom: 24px;
  color: #e2e8f0;
}

.success-message-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.success-message {
  background-color: #0a2647;
  color: white;
  padding: 24px;
  border-radius: 10px;
  text-align: center;
  animation: fadeIn 0.3s ease-out;
}

.success-message h2 {
  margin: 0 0 16px 0;
  font-size: 24px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 