.add-note-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add-note-modal {
  background-color: #0a2647;
  border-radius: 10px;
  padding: 24px;
  width: 90%;
  max-width: 500px;
  color: white;
}

.add-note-modal h2 {
  margin: 0 0 16px 0;
  text-align: center;
  font-size: 24px;
}

.add-note-modal p {
  margin-bottom: 24px;
  text-align: center;
  color: #e2e8f0;
}

.note-content {
  margin-bottom: 24px;
}

.note-content textarea {
  width: 90%;
  padding: 12px;
  border: none;
  border-radius: 6px;
  background-color: white;
  resize: vertical;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.cancel-btn, .confirm-btn {
  padding: 10px 24px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  min-width: 100px;
}

.cancel-btn {
  background-color: #f1f4f8;
  color: #4a5568;
}

.confirm-btn {
  background-color: #ef5350;
  color: white;
}

.confirm-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 