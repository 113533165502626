.page-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.assign-items-container {
  flex: 1;
  padding: 20px;
  background-color: #f5f7fa;

  .header {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 24px;
        font-weight: 500;
        color: #0a2647;
      }

      .job-details {
        background-color: #0a2647;
        padding: 15px;
        border-radius: 8px;
        min-width: 300px;

        .details-row {
          display: flex;
          justify-content: space-between;
          gap: 12px;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .detail-item {
          display: flex;
          align-items: center;
          gap: 8px;
          color: white;
          padding: 4px 8px;
          border-radius: 4px;
          min-width: 120px;

          .icon {
            font-size: 16px;
          }

          span {
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    .required-section,
    .assigned-section {
      flex: 1;
      background-color: white;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      h2 {
        margin: 0;
        margin-bottom: 15px;
        color: #0a2647;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        th, td {
          padding: 12px;
          text-align: left;
          border-bottom: 1px solid #dee2e6;
        }

        th {
          background-color: #e9ecef;
          font-weight: 600;
        }

        .negative {
          color: red;
        }

        .error {
          background-color: #ffebee;
        }
      }
    }
  }

  .actions {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    button {
      padding: 12px 24px;
      border: none;
      border-radius: 6px;
      font-weight: 500;
      cursor: pointer;
      min-width: 120px;
      transition: background-color 0.2s;

      &.cancel-btn {
        background-color: #f1f4f8;
        color: #4a5568;
      }

      &.close-btn {
        background-color: #ef5350;
        color: white;
      }

      &.request-btn {
        background-color: #1a4b8f;
        color: white;
      }

      &.assign-btn {
        background-color: #1a4b8f;
        color: white;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.quantity-input {
  width: 80px;
  padding: 6px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  text-align: center;
  
  &:focus {
    outline: none;
    border-color: #1a4b8f;
    box-shadow: 0 0 0 2px rgba(26, 75, 143, 0.2);
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.error {
  background-color: #ffebee;
  
  .quantity-input {
    border-color: #ef5350;
    
    &:focus {
      border-color: #ef5350;
      box-shadow: 0 0 0 2px rgba(239, 83, 80, 0.2);
    }
  }
}

.negative {
  color: #ef5350;
  font-weight: 500;
}

td.negative {
  animation: flash 1s;
}

@keyframes flash {
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(239, 83, 80, 0.1);
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .add-item-btn {
    padding: 8px 16px;
    background-color: #1a4b8f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: #153c72;
    }
  }
}

.delete-btn {
  background: none;
  border: none;
  color: #ef5350;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;

  &:hover {
    background-color: rgba(239, 83, 80, 0.1);
  }
}

.add-item-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .modal-content {
    background-color: white;
    padding: 24px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;

    h3 {
      margin: 0 0 20px 0;
      color: #0a2647;
    }
  }

  .search-container {
    position: relative;
    margin-bottom: 20px;

    input {
      width: 90%;
      padding: 12px;
      border: 1px solid #dee2e6;
      border-radius: 6px;
      font-size: 14px;

      &:focus {
        outline: none;
        border-color: #1a4b8f;
        box-shadow: 0 0 0 2px rgba(26, 75, 143, 0.2);
      }
    }
  }

  .items-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    max-height: 300px;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .item-option {
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #f8f9fa;
    }

    .item-code {
      width: 100px;
      color: #666;
    }

    .item-name {
      flex: 1;
    }

    .item-stock {
      color: #666;
      margin-left: 16px;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-modal {
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h3 {
    margin: 0 0 16px 0;
    color: #0a2647;
  }

  p {
    margin: 0 0 24px 0;
    color: #4a5568;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button {
      padding: 8px 16px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-weight: 500;

      &.cancel-btn {
        background-color: #f1f4f8;
        color: #4a5568;

        &:hover {
          background-color: #e2e8f0;
        }
      }

      &.confirm-btn {
        background-color: #ef5350;
        color: white;

        &:hover {
          background-color: #e53935;
        }
      }
    }
  }
}

.technician-modal {
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  h3 {
    margin: 0 0 20px 0;
    color: #0a2647;
  }

  .technicians-list {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
  }

  .technician-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #f8f9fa;
    }

    .tech-name {
      font-weight: 500;
      color: #2d3748;
    }

    .tech-assignments {
      color: #718096;
      font-size: 14px;
    }
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button {
      padding: 8px 16px;
      border: none;
      border-radius: 6px;
      cursor: pointer;
      font-weight: 500;

      &.cancel-btn {
        background-color: #f1f4f8;
        color: #4a5568;

        &:hover {
          background-color: #e2e8f0;
        }
      }
    }
  }
} 