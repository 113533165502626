// Principal window colors
$top_butt_bar_pri_color: #1A4795;
$main_contend_pri_color: #EAF2FF;
$side_bar_pri_color: #4967BA;

$menu_butt_pri_color: #d0e1ff;
$menu_butt_icon_pri_color: #1A4795;

$summary_pri_color: #5C81B9;
$input_container_pri_color: #EAF2FF;

$main_menu_icon_primary_color: #113c90;

// Tables style
$table_header: #194695;
$table_header_text: #ffffff;

$table_row_text_color: #000000;
$table_row_color: #d0e1ff;
$table_row_hover: #bdc3e7;
$table_row_selected: #59bbfd;
//$table_row_selected: #33abfc;

$item_selected: #a3c3fe;

// User rols
$user_role_badge_text_color: #ffffff;
$user_role_badge_color: #5c81b8;


// PopUp Window
$pop_up_alert_wind_pri_color: #0A3760;
$pop_up_form_wind_pri_color: #3B6993;

// Confirmation Buttons colors
$button_strong_pri_color: #F2002A;
$button_weak_pri_color: #007AFF;
$button_subbmit_pri_color: #1A4795;
$button_camera_pri_color: #ACFFCD;
$button_primary_color: #1A4795;
// SideBar Buttons colors
$side_bar_buttons: #4967BA;

// DGV Buttons colors
$button_delete_pri_color: #ffcccb;
$button_edit_pri_color: #ffffcc;
$button_create_pri_color: #ACFFCD;
$icon_delete_pri_color: #FF6161;
$icon_edit_pri_color: #ffbb01;
$icon_create_pri_color: #62D188;

// Job state colors
$job-unassigned-color: #39ACB9;
$job-assigned-color: #032156;
$job-parts-ordered-color: #1A4795;
$job-in-process-color: #62D188;
$job-repair-complete-color: #07923E;
$job-parts-approval-color: #fdc408;
$job-po-required-color: #C9720E;
$job-mgmt-approval-needed-color: #BA495C;
$job-closed-color: #434342;
$job-pause: #8FA93A;
$job-unknow-color: #757575;

:export {
    jobUnassignedColor: $job-unassigned-color;
    jobAssignedColor: $job-assigned-color;
    jobPartsOrderedColor: $job-parts-ordered-color;
    jobInProcessColor: $job-in-process-color;
    jobRepairCompleteColor: $job-repair-complete-color;
    jobPartsApprovalColor: $job-parts-approval-color;
    jobPORequiredColor: $job-po-required-color;
    jobMGMTApprovalNeedColor: $job-mgmt-approval-needed-color;
    jobClosedColor: $job-closed-color;
    jobPause: $job-pause;
    jobUnknow: $job-unknow-color;
}
